<template>
<div>
<div class="users" v-show="loggedin">
  <h2 style="margin-bottom:10px;color:white">Builds </h2>
  <v-btn class="ma-2" @click="dialog1 = true" style="color:#263AA2">Add</v-btn>
  <v-dialog v-model="dialog1" max-width="40%" :fullscreen="$vuetify.breakpoint.smAndDown">
    <form @submit.prevent="add" class="updateusers">
      <validation-provider
        v-slot="{ errors }"
        name="Build Name"
        rules="required"
      >
      <v-text-field
        v-model="buildname"
        :error-messages="errors"
        label="Build Name"
      ></v-text-field>
      </validation-provider>

      <validation-provider
        v-slot="{ errors }"
        name="Build Logo Link"
        rules="required"
      >
      <v-text-field
        v-model="buildlogo"
        :error-messages="errors"
        label="Build Logo Link"
      ></v-text-field>
      </validation-provider>

      <validation-provider
        v-slot="{ errors }"
        name="Build By"
        rules="required|min:8"
      >
      <v-select
        v-model="by"
        :error-messages="errors"
        label="Build By"
        :items="maintainers"
        required
      ></v-select>
      </validation-provider>

      <validation-provider
        v-slot="{ errors }"
        name="Build Tag"
        rules="required"
      >
      <v-select
        v-model="tag"
        :error-messages="errors"
        label="Build Tag"
        :items="tags"
        required
      ></v-select>
      </validation-provider>

      <validation-provider
        v-slot="{ errors }"
        name="Version"
        rules="required"
      >
      <v-text-field
        v-model="version"
        :error-messages="errors"
        label="Version"
        required
      ></v-text-field>
      </validation-provider>

      <validation-provider
        v-slot="{ errors }"
        name="Build Date"
        rules="required"
      >
      <v-text-field
        type="date"
        v-model="builddate"
        :error-messages="errors"
        label="Build Date"
        required
      ></v-text-field>
      </validation-provider>

      <validation-provider
        v-slot="{ errors }"
        name="Device"
        rules="required"
      >
      <v-select
        v-model="device"
        :error-messages="errors"
        label="Device"
        :items="devices"
        required
      ></v-select>
      </validation-provider>

      <validation-provider
        v-slot="{ errors }"
        name="Type"
        rules="required"
      >
      <v-select
        v-model="type"
        :error-messages="errors"
        label="Type"
        :items="types"
        required
      ></v-select>
      </validation-provider>

      <validation-provider
        v-slot="{ errors }"
        name="Download link"
         rules="required"
      >
      <v-text-field
        v-model="download"
        :error-messages="errors"
        label="Download link"
        :items="status"
      ></v-text-field>
      </validation-provider>
     <label style="color:#636c72;margin-bottom:1%;">Changelog/Notes</label>
      <vue-editor
        v-model="notes"
        :error-messages="errors"
        required
      ></vue-editor>
      <v-btn
        color="primary"
        class="mr-4"
        type="submit"
      >
      Submit
      </v-btn>
      <v-btn
        class="ma-2"
        @click="dialog1 = false"
      >
      Close
      </v-btn>
    </form>
  </v-dialog>
      <div class="panels">
        <v-expansion-panels>
            <v-expansion-panel
            v-for="(item,i) in this.length"
            :key="i"
            style="background:#467cc1;color:white;"
            >
            <v-expansion-panel-header>
                <div>{{ data[i].name }}</div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
                <div class="paneldata">
                <div class="col-md-4">
                    <img :src="data[i].logo" class="img-fluid rounded" :alt="data[i].name">
                </div>
                <div class="panel">
                    <p>By : <a :href="data[i].maintainer" style="color:white">{{ data[i].maintainer }}</a></p>
                    <p>Tag : {{ data[i].tag }}</p>
                    <p>Version : {{ data[i].version }}</p>
                    <p>Builddate : {{ data[i].build_date }}</p>
                    <p>Device : {{ data[i].device }}</p>
                    <p>Type : {{ data[i].type }}</p>
                    <p>Download link : <a :href="data[i].download" style="color:white">{{ data[i].name }}</a></p>
                    <p style="white-space: pre-line;">Changelog : <br>{{ data[i].note | strippedContent }}</p>
            </div>
            </div>
          <v-btn
            class="ma-2"
            style="color:#263AA2"
            @click="dialog = true"
          >
            Edit
          </v-btn>

            <v-dialog v-model="dialog" max-width="40%" :fullscreen="$vuetify.breakpoint.smAndDown">
              <form @submit.prevent="update" class="updateusers">
                <validation-provider
                  v-slot="{ errors }"
                  name="Build Name"
                >
                  <v-text-field
                    :value="data[i].name"
                    ref="buildname"
                    :error-messages="errors"
                    label="BuildName"
                    disabled
                  ></v-text-field>
                </validation-provider>

                <validation-provider
                  v-slot="{ errors }"
                  name="Build Logo Link"
                  rules="required"
                >
                  <v-text-field
                    ref="buildlogo"
                    :value="data[i].logo"
                    :error-messages="errors"
                    label="Build Logo Link"
                    required
                  ></v-text-field>
                </validation-provider>

                <validation-provider
                  v-slot="{ errors }"
                  name="Build By"
                  rules="required"
                >
                  <v-select
                    ref="by"
                    :value="data[i].maintainer"
                    :error-messages="errors"
                    label="Build By"
                    :items="maintainers"
                    required
                  ></v-select>
                </validation-provider>

                <validation-provider
                  v-slot="{ errors }"
                  name="Build Tag"
                  rules="required"
                >
                  <v-select
                    :value="data[i].tag"
                    ref="tag"
                    :error-messages="errors"
                    label="Build Tag"
                    :items="tags"
                    required
                  ></v-select>
                </validation-provider>

                <validation-provider
                  v-slot="{ errors }"
                  name="Version"
                  rules="required"
                >
                  <v-text-field
                    ref="version"
                    :value="data[i].version"
                    :error-messages="errors"
                    label="Version"
                    required
                  ></v-text-field>
                </validation-provider>

                <validation-provider
                  v-slot="{ errors }"
                  name="Build Date"
                  rules="required"
                >
                  <v-text-field
                   type="date"
                    ref="builddate"
                    :value="data[i].build_date"
                    :error-messages="errors"
                    label="Build Date"
                    required
                  ></v-text-field>
                </validation-provider>

                <validation-provider
                  v-slot="{ errors }"
                  name="Device"
                  rules="required"
                >
                  <v-select
                    :value="data[i].device"
                    ref="device"
                    :error-messages="errors"
                    label="Device"
                    :items="devices"
                    required
                  ></v-select>
                </validation-provider>

                <validation-provider
                  v-slot="{ errors }"
                  name="Type"
                  rules="required"
                >
                  <v-select
                    :value="data[i].type"
                    ref="type"
                    :error-messages="errors"
                    label="Type"
                    :items="types"
                    required
                  ></v-select>
                </validation-provider>

                <validation-provider
                  v-slot="{ errors }"
                  name="Download link"
                  rules="required"
                >
                  <v-text-field
                    ref="download"
                    :value="data[i].download"
                    :error-messages="errors"
                    label="Download link"
                    required
                  ></v-text-field>
                </validation-provider>

                <label style="color:#636c72;margin-bottom:1%;">Changelog/Notes</label>
                <vue-editor
                    ref="notes"
                    :value="data[i].note"
                    :error-messages="errors"
                    required
                ></vue-editor>

                <v-btn
                color="primary"
                  class="mr-4"
                  type="submit"
                >
                  Submit
                </v-btn>
                <v-btn
                  class="ma-2"
                  @click="dialog = false"
                >
                  Close
                </v-btn>
              </form>
            </v-dialog>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
  </div>
  <v-alert outlined transition="slide-y-transition" type="success" text :value="updatedbuilddata"> Build Data Updated successfully. </v-alert>
  <v-alert outlined type="error" text :value="failedupdatebuilddata"> Failed to Update Build Data. </v-alert>
  <v-alert outlined transition="slide-y-transition" type="success" text :value="addedbuilddata"> Build Created successfully. </v-alert>
  <v-alert outlined type="error" text :value="failedaddbuilddata"> Failed to Create Build. </v-alert>
</div>
  <div class="notfound" v-show="notloggedin">
    <img src="../assets/404.png" alt="404(Not Found)">
    <h1>404</h1>
    <p>The page you are looking for is not available</p>
  </div>
</div>
</template>
<script>
import { required, min } from 'vee-validate/dist/rules'
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'
import { VueEditor } from "vue2-editor";
import axios from 'axios';

  setInteractionMode('eager')

  extend('required', {
    ...required,
    message: '{_field_} can not be empty',
  })

  extend('min', {
    ...min,
    message: '{_field_} must have atlest {length} characters',
  })

  export default {
    name: 'Builds',
    components: {
      ValidationProvider,
      ValidationObserver,
      VueEditor,
    },
    data: () => ({
      tags: ['Official', 'UnOfficial'],
      devices: ['RMX1971', 'r5x'],
      types: ['rom', 'recovery', 'kernel'],
      length: '',
      data: '',
      current_time: '',
      dialog: false,
      dialog1: false,
      updatedbuilddata: false,
      failedupdatebuilddata: false,
      addedbuilddata: false,
      failedaddbuilddata: false,
      loggedin: false,
      notloggedin: true,
      maintainers: [],
    }),

    mounted () {
        this.checkloginstatus();
        this.getbuilds();
        this.getmaintainernames();
    },

    filters: {
        strippedContent: function(string) {
            return string.replace(/<\/?[^>]+>/ig, "\n"); 
        }
    },

    methods: {
        checkloginstatus() {
            this.current_time = Math.round(+new Date()/1000)
            let data = JSON.parse(sessionStorage.getItem("data"))
            if (data && data['builds']=='Y' && sessionStorage.getItem("expire_time") > this.current_time) {
              this.loggedin = true,
              this.notloggedin = false
            } else {
              sessionStorage.clear();
            }
        },
        getmaintainernames() {
            let config = {
                method : 'get',
                url : `https://kharame-devices.herokuapp.com/getMaintainersName`,
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            };
            axios(config)
            .then((response) => {
                response.data.message
                this.maintainers = response.data.message
            })
        },
        getbuilds() {
            let config = {
                method : 'get',
                url : `https://kharame-devices.herokuapp.com/getBuildsData`,
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            };
            axios(config)
            .then((response) => {
                response.data.message
                this.length = response.data.message.length
                this.data = response.data.message
            })
        },
      update () {
        let data = {
            buildname : this.$refs.buildname[0].value,
            buildlogo : this.$refs.buildlogo[0].lazyValue,
            by : this.$refs.by[0].selectedItems[0],
            tag : this.$refs.tag[0].lazyValue,
            version : this.$refs.version[0].lazyValue,
            builddate : this.$refs.builddate[0].lazyValue,
            device: this.$refs.device[0].selectedItems[0],
            type: this.$refs.type[0].selectedItems[0],
            download : this.$refs.download[0].lazyValue,
            notes : this.$refs.notes[0]._data.quill.editor.delta.ops[0].insert,
        };
          let config = {
            method : 'post',
            url : `https://kharame-devices.herokuapp.com/updatebuild`,
            headers: {
              'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            },
            data: data
          };
          axios(config)
          .then((response) => {
            if (response.data && response.data.status === "S" && response.data.message === "S") {
              this.dialog = false;
              this.updatedbuilddata = true;
              this.getbuilds();
              setTimeout(() => this.updatedbuilddata = false, 5000);
            } else {
              this.dialog = false
              this.failedupdatebuilddata = true;
              setTimeout(() => this.failedupdatebuilddata = false, 5000);
            }
          })
      },
      add () {
        let data = {
            buildname : this.buildname,
            buildlogo : this.buildlogo,
            by : this.by,
            tag: this.tag,
            version: this.version,
            builddate: this.builddate,
            device: this.device,
            type: this.type,
            download: this.download,
            notes: this.notes,
        };
          let config = {
            method : 'post',
            url : `https://kharame-devices.herokuapp.com/createbuild`,
            headers: {
              'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            },
            data: data
          };
          axios(config)
          .then((response) => {
            if (response.data && response.data.status === "S" && response.data.message === "S") {
              this.dialog1 = false;
              this.addedbuilddata = true;
              this.getbuilds();
              setTimeout(() => this.addedbuilddata = false, 5000);
            } else {
              this.dialog1 = false
              this.failedaddbuilddata = true;
              setTimeout(() => this.failedaddbuilddata = false, 5000);
            }
          })
      },
    },
  }
</script>
<style scoped>
.users {
  width : 60%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 4%;
}
.panels {
    margin-top: 2%;
    margin-left: auto;
    margin-right: auto;
}
.paneldata {
    display: flex;
}
.panel {
    margin-left: 2.5%;
}
@media (max-width: 550px) {
    .paneldata {
        display: block;
    }
    .panel {
        margin-top: 10%;
    }
}
.updateusers {
  background: white;
  padding: 10%;
}
.notfound {
	text-align: center;
	margin-top: 7%;
  color: white;
}
</style>